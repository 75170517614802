import AgGridTable, { ColumnTypes } from 'components/AgGridTable';
import { useMemo } from 'react';
import { RiskStatusBox, Typography } from 'components';
import { Flexbox } from 'components';
import styles from '../styles.module.scss';
import classNames from 'classnames/bind';
import { getLinkedColumnContextMenuItems } from 'components/AgGridTable';
import { AgColumn } from 'components/AgGridTable';
import { useSelector } from 'react-redux';
import {
    notPlannedCarryoverInitiativesSelector,
    plannedInitiativesSelector,
} from 'store/roadmapMetrics-slice';
import { getEnumKeyByEnumValue } from 'utils';
import { compareEnumValues } from 'utils/tableSorting';
import { InitiativeStatus } from 'utils/types';
import { ICellRendererParams, KeyCreatorParams } from 'ag-grid-community';
import SidePanelDrawer from 'components/SidePanelDrawer';
import AlertsSidePanel from 'components/AlertsSidePanel';

const classes = classNames.bind(styles);

const InitiativesTable = () => {
    const plannedInitiatives = useSelector(plannedInitiativesSelector);
    const notPlannedCarryoverInitiatives = useSelector(notPlannedCarryoverInitiativesSelector);

    const initiativeLists = [
        {
            title: 'Planned Initiatives',
            data: plannedInitiatives,
        },
        {
            title: 'Not Planned Carryover Initiatives',
            data: notPlannedCarryoverInitiatives
        }
    ];

    const columns: AgColumn[] = useMemo(() => {
        return [
            {
                colType: ColumnTypes.Linked,
                headerName: 'Title',
                field: 'title',
                defaultValue: 'Untitled',
                minWidth: 250,
                sortable: true,
                link: '/initiatives/initiative',
                linkParam: 'id',
                wrapText: true,
                autoHeight: true,
            },
            {
                colType: ColumnTypes.SimpleSelect,
                headerName: 'Owner',
                field: 'owner',
                minWidth: 200,
                sortable: true,
                valueGetter: (params: any) => {
                    return params.data ? params.data.owner?.fullName : '';
                },
                valueFormatter: (params: any) => {
                    return params?.value?.fullName;
                },
            },
            {
                colType: ColumnTypes.Progress,
                headerName: 'Progress',
                field: 'progress',
                sortable: true,
                minWidth: 200,
            },
            {
                colType: ColumnTypes.PRDScore,
                headerName: 'PRD Score',
                field: 'score',
                sortable: true,
                minWidth: 120,
            },
            {
                headerName: 'Risk Level',
                field: 'delayRisk',
                minWidth: 130,
                sortable: true,
                cellRenderer: (params: ICellRendererParams) => {
                    if (params.node.group) {
                        if (params.node.field !== 'delayRisk') {
                            return null
                        } else if (params.node.groupData) {
                            const value = params.node.groupData['ag-Grid-AutoColumn']
                            return value ? <Flexbox align className={classes('h-full')}>
                                <RiskStatusBox category={value?.category} withoutAlerts />
                            </Flexbox> : null
                        }
                    } else {
                        return (
                            <SidePanelDrawer
                                actionElement={
                                    (props: any) => (
                                        <Flexbox align className={classes('h-full')} {...props}>
                                            <RiskStatusBox
                                                category={params.value?.category}
                                                alertsCount={params.value?.reasons?.length}
                                            />
                                        </Flexbox>
                                    )
                                }
                            >
                                {
                                    !!params.value?.reasons?.length && <AlertsSidePanel
                                        title={params.data?.name}
                                        reasons={params.value?.reasons}
                                        format={'markdown'}
                                        lastEvaluationDate={params.value?.lastEvaluationDate}
                                    />
                                }
                            </SidePanelDrawer>
                        );
                    }
                },
                comparator: (risk1: any, risk2: any) => {
                    const risk1Value = risk1?.value;
                    const risk2Value = risk2?.value;

                    const isNullable = (value: any) => value === null || value === undefined;

                    if (isNullable(risk1Value) && isNullable(risk2Value)) {
                        return 0;
                    }
                    if (isNullable(risk1Value)) {
                        return -1;
                    }
                    if (isNullable(risk2Value)) {
                        return 1;
                    }

                    if (risk1Value === 0 && risk2Value === 0) {
                        return 0;
                    }
                    if (risk1Value === 0) {
                        return -1;
                    }
                    if (risk2Value === 0) {
                        return 1;
                    }

                    return risk1Value > risk2Value ? 1 : risk1Value === risk2Value ? 0 : -1;
                },
                keyCreator: (params: KeyCreatorParams) => {
                    return params.value ? params.value?.category : 'N/A'
                },
                hide: true,
            },
            {
                colType: ColumnTypes.Chip,
                headerName: 'Products',
                field: 'products',
                sortable: true,
                hide: true,
            },
            {
                colType: ColumnTypes.Priority,
                headerName: 'Priority',
                field: 'priority',
                sortable: true,
                hide: true,
            },
            {
                colType: ColumnTypes.SimpleSelect,
                headerName: 'Status',
                field: 'status',
                minWidth: 180,
                sortable: true,
                comparator: (d1: any, d2: any) => {
                    return compareEnumValues(d1, d2, InitiativeStatus)
                },
                valueFormatter: (params: any) => {
                    return getEnumKeyByEnumValue(InitiativeStatus, params.value) || ''
                },
                hide: true,
            },
            {
                colType: ColumnTypes.JiraLink,
                headerName: 'Issue link',
                field: 'key',
                sortable: true,
                autoHeight: true,
                hide: true,
            },
            {
                colType: ColumnTypes.Circle,
                headerName: 'Teams',
                field: 'teams',
                sortable: true,
                hide: true,
            },
            {
                colType: ColumnTypes.Date,
                headerName: 'Development Start Date',
                field: 'startDate',
                minWidth: 120,
                sortable: true,
                hide: true,
            },
            {
                colType: ColumnTypes.Date,
                headerName: 'Development End Date',
                field: 'endDate',
                minWidth: 120,
                sortable: true,
                hide: true,
            },
            {
                colType: ColumnTypes.Date,
                headerName: 'Live Date',
                field: 'releaseDate',
                minWidth: 120,
                sortable: true,
                hide: true,
            },
            {
                colType: ColumnTypes.TimeAgo,
                headerName: 'Last Updated',
                field: 'lastModifiedDate',
                minWidth: 120,
                sortable: true,
                hide: true,
            },
        ]
    }, [])

    return (
        <Flexbox vertical className={classes('gap-6', 'initiatives-table-container')}>
            {initiativeLists.filter(({ data }) => data && data.length > 0).map(({ title, data }) => (
                <Flexbox key={title} vertical className={classes('initiatives-table', 'gap-4')}>
                    <Typography className={classes('initiatives-table-title')}>{title}</Typography>
                    <AgGridTable
                        data={data || []}
                        columns={columns}
                        domLayout="autoHeight"
                        getContextMenuItems={params => {
                            if (params.defaultItems) {
                                const rowData = params?.node?.data;
                                const url = `/initiatives/initiative/${rowData.id}`;

                                const linkItems = getLinkedColumnContextMenuItems(params, url, 'title')
                                const menuItems = [
                                    ...params.defaultItems,
                                    ...linkItems,
                                ]

                                return menuItems
                            } else {
                                return []
                            }
                        }}
                    />
                </Flexbox>
            ))}
        </Flexbox>
    )
}

export default InitiativesTable;